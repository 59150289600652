import React, { Component } from 'react';

class Kurser extends Component {

  render () {
    return (
            <center>
      <div className="info" style={{"text-align": "left"}}>
<center><h1>Kurser på folkmusikkaféet</h1></center>
<br/>
<center>
  <small>Deltagare i fredagskurser erhåller 50% rabatt på konsertkvällar så länge kursen pågår.<br/>
    U & S = Ungdomar upp till 25 år samt studerande.</small>
</center>
<br/><br/>
<center>
<img className="kursbild-mindre fade-in-fast" style={{"padding-right": "40px" }}src="img/arkiv/jon_olov.jpg"></img>
<img className="kursbild-mindre fade-in-fast" src="img/arkiv/dan_palmqvist_small.jpg"></img>
</center><br/>
<center><h3>Spelmanslaget</h3></center>
<center>
<div className="kompakt">
Kursen hålls fredagar kl 17:30 - 19:15 med start 13 september och omfattar hela hösten och alla med akustiska instrument är välkomna.
Vi ser gärna att du har något eller några års erfarenhet på ditt instrument. Som kompmusiker ser vi helst att du är relativt självständig och kan lite grunder i folkmusik.<br/><br/>
Kursinstruktör: Jon-Olov Woxlin och Dan Palmqvist <br/><br/>
Kostnad: 600 kr (ung och stud. 350 kr) betalas in på pg 107741-1 eller Swish 1236085450. Ange namn + "spelmanslaget".<br/><br/>
<small>För mer information: Dan Palmqvist, dan.palmqst@gmail.com</small>
       </div>
</center>
  <center>
    <h3>Nybörjarkurs i dans till folkmusik</h3>
  </center>
  <center>
    Danskurs på Folkmusikkaféet,<br/><small>sex fredagskvällar med start den 20/9 och avslutning 25/10</small><br/><br/>
    <div className="kompakt">
Välkomna till en nybörjarkurs med grunderna i schottis, vals och några polskor. Vi lär ut
grundläggande danser som är användbara på Folkmusikkaféet, spelmansstämmor och
liknande arrangemang med dans till folkmusik. Kursen riktar sig till rena nybörjare och er
som dansat förut men känner att en riktig grundkurs skulle göra gott. Exempel på
kursinnehåll kan vara schottis, vals, ”rundpolska”, slängpolska, trisspolska och tillhörande
      dansteknik.<br/><br/>


Medtag skor som har sulor ”med glid”.<br/><br/>
Kursinstruktörer: Ingela och Håkan Lindberg<br/>
      Spelman: Björn Johnsson<br/><br/>
      Kursavgift 600 kr (ung & stud 300 kr)<br/><br/>

      Kursen arrangeras i samarbete med Bilda.<br/><br/>
      <b>Länk till anmälan finns här:</b><br/>
      <a href="https://www.bilda.nu/arr/1226500/nyborjarkurs-i-dans-till-folkmusik-922073">https://www.bilda.nu/arr/1226500/nyborjarkurs-i-dans-till-folkmusik-922073</a><br/>
      <br/>
  Frågor kan ställas till Håkan Lindberg, tel. 0767-133250 eller maila till kurser@folkmusikkafeet.net 
  </div>
  </center>

  <center>
    <h3>Viskurs med Kristin "Fornfela" Svensson</h3>
    <img className="kursbild-mindre fade-in-fast" src="img/program/ht2024/Kristin_Svensson_Foto_Johan_Lundberg.jpg"></img>
    <br/>
    <br/>
  </center>

      <center>Viskurs 22-23/11 med Kristin ”Fornfela” Svensson<br/><small>
Visor efter Sven-Ingvar Heij och andra
spelmän från Västergötland.
</small><br/><br/>
    <div className="kompakt">
Väkommen till kurs i Västgötska folkvisor med sångerskan och folkmusikern Kristin
&quot;Fornfela&quot; Svensson. Kristin är frilansande musiker med många års turnérande bakom sig.
Hon har ett stort intresse för de västgötska folkvisorna och har en stor visskatt att dela med
sig av. Hennes ledstjärna och förebild in i folkmusiken var Mariestadsspelmannen Sven-
Ingvar Heij och visorna efter honom har alltid en central plats påhennes viskurser. För Kristin
kommer aldrig en visa utan en berättelse. Varje visa bäddas varsamt in i sin historiska
kontext och presenteras tillsammans med berättelsen om spelmannen som en gång sjöng
visan. Välkommen till en viskurs på västgötska där energiska trinnpolskor varvas med
tragikomiska kärleksvisor, vackra koraler och lustiga vaggvisor.
Kursen börjar med en visstuga på Folkmusikkaféet Allégården på fredagen och fortsätter
under lördagen på Gräfsnäsgården.

      <br/><br/>

Tider: Fredag 22/11 kl 18:30 – 19:30 (Allégården) och lördag 23/11 kl 10:00 – 17:00
(Gräfsnäsgården).<br/><br/>
Kursavgift: 450 kr (ung &amp; stud 250 kr)
<br/><br/>
Kursen arrangeras i samarbete med Bilda. Kursanmälan görs senast 1/11.<br/><br/><b>Länk till anmälan finns här:</b><br/>
<a href="https://www.bilda.nu/arr/1227831/kurs-i-vastgotska-folkvisor-922294">https://www.bilda.nu/arr/1227831/kurs-i-vastgotska-folkvisor-922294</a><br/><br/>
Frågor kan ställas till Kristin Svensson, tel. 070-342 86 25 eller maila till kurser@folkmusikkafeet.net.<br/><br/> 

Kursdeltagare erhåller 50 % rabatt på konsertkvällar under kursen (detta kan ej kombineras
med andra rabatter).
  </div>
  </center>

<br/>
<br/>
<center><img className="kursbild fade-in-fast" src="img/tillsammansgruppen.jpg"></img></center><br/>
<center><h3>Tillsammansgruppen</h3></center>
<center>
<div className="kompakt">
Genom alla tider har vi lekt på fest och till vardags, barn som vuxen.<br/>
Tillsammansgruppen ger dig möjlighet att ta del av denna roliga tradition.<br/>
Stora som små är varmt välkomna att hänga med i vindlande långdanser och visor.<br/>
Det är motion för både kropp som knopp!
<br/><br/>
Tider: 18:00 till 19:30 med tid för fika.<br/>
Instruktörer: Gunnel Sjögren och Gunilla Sellersjö <br/>
Datum: 13/9, 1/11, 22/11<br/>
Kostnad: Gratis för barn i sällskap med någon vuxen<br/>
</div>
</center>
</div>
</center>
      );
  }

}


export default Kurser;





