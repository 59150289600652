import React, { Component } from 'react';
import Concert from "./concert"
import './concert.css'

import ScrollIndicator from "./ScrollIndicator.jsx";

/* Here is what is displayed on top, first of all */
const openingSection = (
  <div className="program-heading">
    <center>
       <h1>Varmt välkomna till Folkmusikkaféet</h1>
       Vi är en mötesplats för levande folkmusik och dans i Göteborg.<br/>Folkmusik och dans varje fredag.<br/><br/>
  <small>
Bli medlem och få rabatt på inträdet. Du bidrar till att vi finns!<br/>
Du blir medlem i entrén på en konsertkväll<br/> eller genom att sätta in 50 kr på PG 107741-1, alt. Swish 1236279434. <br/>
  Ange namn, adress, telefonnummer och e-postadress.<br/>Medlemskap gäller per kalenderår.<br/>
<br/>
Konsertbiljetter köpes i entrén. Ingen förbokning.<br/>
Spel och danskvällar, heldanskväll och spelmansstämma har Fri entré.<br/>
  </small>
  </center>
  </div>);

/* Here the program is inserted! */
const theProgram = [

<Concert
  title="Spel- och danskväll"
  date="2024-09-13"
  imgMaxWidth="95%"
  cost={0} // Frivillig entréavgift
  img="img/program/ht2024/heldanskvall.jpg"
>
  <div className="pBody">
    <center>
      Folkmusikkaféet inleder höstsäsongen med en spel- och danskväll – dans till spellista. Två grupper är bokade att inleda kvällen, sedan är det fritt fram att skriva upp sig på listan.<br/><br/>
      
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelkurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Spelmanslaget (se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>

        <tr>
          <td>18:00-19:30</td>
          <td>Tillsammansgruppen</td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Håkan och Ingela Lindberg lär ut grundpolska. Spelman: Björn Johnsson</small></td>
        </tr>
        <tr>
          <td>20:00-23:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Hisingens spelmanslag samt Dan Olsson &amp; Lars-Gunnar Franzén inleder, sedan följer öppen spellista</small></td>
        </tr>
      </table><br/>
      <small>Kafévärd denna afton: Skjortor och Särkar</small>
    </center>
  </div>
</Concert>
  ,
<Concert
  title="Fallstråk"
  date="2024-09-20"
  imgMaxWidth="95%"
  cost={160} costYoung={130}
  img="img/program/ht2024/Fallstrak.jpg"
>
  <div className="pBody">
    <center>
      Fallstråk bjuder på folkmusik från Bohuslän &amp; Västergötland, och pärlor ur vår svenska och norska rika visskatt på fiol, nyckelharpa, gitarr, dragspel och sång.<br/><br/>
      Mia Gunberg är från Lur och bor på Gårdemyr utanför Grebbestad. Den musikaliska källan strömmar från hennes hemvist i nordligaste Bohuslän.<br/><br/>
      Billy Lätt är från Skultorp, har bott i över 30 år i Telemark i Norge och bor numera på Gårdemyr utanför Grebbestad. Han är riksspelman på västgötalåtar.<br/><br/>
      Björn Restin är multimusikern från Nossebro där han fortfarande bor och jobbar. Björn har utvecklat sin egen spelstil på dragspelet där han utnyttjar instrumentets hela register. Han är bred musikaliskt och behärskar både jazz, klassiskt och folkmusik.<br/><br/>

      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelkurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Spelmanslaget (se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>

        <tr>
          <td>18:00-19:30</td>
          <td>Danskurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>första gången (se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Peter Nordqvist och Marie Schoonderwaldt lär ut schottis. Spelmän: Karin Westerståhl och Lennart Ehn.</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Visstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Mikael Rittri lär ut kärleksvisor</small></td>
        </tr>
        <tr>
          <td>20:00-20:50</td>
          <td>Konsert: Fallstråk</td>
        </tr>
        <tr>
          <td>20:50-23:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td><small>I pausen spelar Bagaregårdens spelmanslag</small></td>
        </tr>
      </table><br/>
      <small>Kafévärd denna afton: Näverluren</small>
    </center>
  </div>
  </Concert>,


<Concert
  title="Stordanskväll: Mats Berglund, Mattias Helje"
  date="2024-09-27"
  imgMaxWidth="95%"
  cost={160} costYoung={130}
img="img/program/ht2024/Mats_Berglund.jpg"
imgTwo="img/program/ht2024/Mattias_Helje_2-min.jpg"
>
  <div className="pBody">
    <center>
      På en stordanskväll är det dans hela kvällen – ingen konsert!<br/><br/>
      Mattias Helje - Springlekarnas värsting! Sedan tonåren har Mattias ägnat sig åt västerdalmusiken. Tidigt lärde han sig låtar hos Kalle Almlöf och har sedan utvecklat ett eget låtspel som gjort honom till en mycket uppskattad dansspelman.<br/><br/>
      Jössehärspolskans värsting Mats Berglund från Köla i västra Värmland har i många år räknats som en av landets främsta spelmän. Karakteristiskt för Mats musik är hans koppling till att spela till dans.<br/><br/>
      Att Mattias och Mats möts i samspel under en Stordanskväll på Allegården är helt naturligt. Bägge två har i sitt musikanteri letat efter ett äldre spel där insikten i äldre spel och dans i gränslandet mot Norge finns som ett eko från äldre tid. Förutom springlekar och jössehäradspolskor blir det även finnskogspols, schottis/rejländer, vals och halling.<br/><br/>

      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelkurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Spelmanslaget (se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>

        <tr>
          <td>18:00-19:30</td>
          <td>Danskurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>(se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Peter Nordqvist och Marie Schoonderwaldt lär ut bakmes och polska från övre Klarälvsdalen. Spelman: Joar Skorpen.</small></td>
        </tr>
        <tr>
          <td>20:00-23:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Mats Berglund &amp; Mattias Helje (solospel och duospel)</small></td>
        </tr>
      </table><br/>
      <small>Kafévärd denna afton: FMK:s styrelse + medarbetare</small>
    </center>
  </div>
</Concert>,


<Concert
  title="Anna-Karin Andersson &amp; John-Francis Goodacre"
  date="2024-10-04"
  imgMaxWidth="95%"
  cost={160} costYoung={130}
  img="img/program/ht2024/andersson_goodacre.jpg"
  imgLink="https://www.instagram.com/goodacre_andersson/"
>
  <div className="pBody">
    <center>
      Ett möte mellan hardingfela och viola d’amore, mellan svenskt och norskt, mellan musiker och dansare.<br/><br/>
      Anna-Karin Andersson (hardingfela, fiol) och John-Francis Goodacre (viola d’amore, fiol) är två spelmän som har hittat sitt musikaliska hem bland de vildvuxna speltraditionerna från Västerdalarna, Värmland och närliggande Norge.
      <br/><br/>De bjuder in dansare och lyssnare till en stor klangvärld där gamla låtar ges nytt liv i lekfullt och nyfiket samspel. Duon träffades första gången på Ransäterstämman och upptäckte en delad passion för riviga danslåtar med inspiration från deras respektive läromästare Mats Edén och Mats Berglund.<br/><br/>
      
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelkurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Spelmanslaget (se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>

        <tr>
          <td>18:00-19:30</td>
          <td>Danskurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>(se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Emelie Ström-Mattsson och Daniel Wallenius lär ut rørospols. Spelmän: Karin Westerståhl och Lennart Ehn.</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Spelstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Lars-Gunnar Franzén lär ut låtar från Värmland och Västerdalarna.</small></td>
        </tr>
        <tr>
          <td>20:00-20:50</td>
          <td>Konsert: Anna-Karin Andersson &amp; John F. Goodacre</td>
        </tr>
        <tr>
          <td>20:50-23:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Anna-Karin Andersson &amp; John F. Goodacre</small></td>
        </tr>
        <tr>
          <td></td>
          <td><small>I pausen spelar Minja Klevebrant</small></td>
        </tr>
      </table><br/>
      <small>Kafévärd denna afton: Polskedanslaget</small>
    </center>
  </div>
</Concert>
  ,


<Concert
  title="Kören Amanda"
  date="2024-10-11"
  imgMaxWidth="95%"
  cost={180} costYoung={150}
  img="img/program/ht2024/Amanda_11_okt.jpg"
  imgLink="https://amanda.nu/"
>
  <div className="pBody">
    <center>
      Sångensemblen Amanda är en unik sammansättning av folk- och världsmusiker, skådespelare, läkare, systemanalytiker och en konstnär som kör taxi. Vi rör oss fritt mellan olika konstnärliga uttryck och inkluderar både egna verk och kända stycken som vi tolkar på vårt sätt. Repertoaren inkluderar rock, pop, folkmusik, klassiskt och ballader. Efter 40 år fortsätter vi utmana gränserna för vad körsång innebär.<br/><br/>
      Efter Amandas konsert spelar Höstrusk och Tagel &amp; Co till dans.<br/><br/>
      <b>OBS!</b> Den här konserten kan förbokas via Billetto, länk finns här: <a href="https://billetto.se/e/konsert-och-danskvall-pa-folkmusikkafeet-koren-amanda-biljetter-1065119?utm_source=organiser&utm_medium=share&utm_campaign=copy_link&utm_content=1">Förbokning</a><br/><br/>

      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelkurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Spelmanslaget (se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>

        <tr>
          <td>18:00-19:30</td>
          <td>Danskurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>(se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Mats Nilsson och Ingegerd Sigfridsson lär ut slängpolska. Spelman: Staffan Folestad.</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Visstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Kenneth Dalbris lär ut visor från Västsverige.</small></td>
        </tr>
        <tr>
          <td>20:00-21:00</td>
          <td>Konsert: Amanda</td>
        </tr>
        <tr>
          <td>21:00-23:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Höstrusk och Tagel &amp; Co</small></td>
        </tr>
      </table><br/>
      <small>Kafévärd denna afton: Kråkstråk</small>
    </center>
  </div>
</Concert>
  ,

<Concert
  title="Glenntown festival: Inver"
  date="2024-10-18"
  imgMaxWidth="95%"
  cost={160} costYoung={130}
  img="img/program/ht2024/231012Inver0204_photo_Ard_Jongsma_-min.jpg"
  imgLink="https://inver.dk/"
>
  <div className="pBody">
    <center>
      Den här kvällen ingår i Glenntown festival. Inver spelar traditionell och nyskriven folkmusik med rötter i de keltiska traditionerna från Irland och Skottland.
      <br/><br/>Kevin Lees (fiol), Rune Barslund (irländska flöjter &amp; dragspel) och Simon Nyberg (gitarr) spelar med respekt för traditionerna och samtidigt med en vilja att utmana och förnya dem. Detta kommer särskilt till uttryck i trions egna kompositioner och fantasifulla arrangemang som ger gruppen ett unikt och samtida sound.<br/><br/>
      
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelkurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Spelmanslaget (se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>

        <tr>
          <td>18:00-19:30</td>
          <td>Danskurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>(se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>The Royal Scottish Country Dance Society Göteborg lär ut skotska danser. <a href="https://rscdsgothenburg.se/">Länk till skottedansarna</a></small></td>
        </tr>
        <tr>
          <td>20:00-21:00</td>
          <td>Konsert: Inver</td>
        </tr>
        <tr>
          <td>21:00-23:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Inver</small></td>
        </tr>
        <tr>
          <td></td>
          <td><small>I pausen spelar Kråkstråk</small></td>
        </tr>
      </table><br/>
      <small>Kafévärd denna afton: Karin med vänner</small><br/>
      <br/>
      <small><i>Den här kvällen är ett samarbete med <a href="https://www.timsig.se">TIMSIG</a></i></small><br/>
      <br/>
      <img src="img/program/ht2024/logo22.png" alt="TIMSIG" style={{width: "100%"}} onClick={() => window.open("https://www.timsig.se/")} />
    </center>
  </div>
  </Concert>,


<Concert
  title="Kulturnatta: Heldanskväll"
  date="2024-10-25"
  imgMaxWidth="95%"
  cost={0} // Frivillig entréavgift
  img="img/program/ht2024/heldanskvall.jpg"
>
  <div className="pBody">
    <center>
      En helkväll för alla dansare. Triol, Västkustfolk och Kristians kapell kommer att spela till dans två halvtimmar var. Vi kan räkna med att det blir mycket polskor men också några gammaldanser.<br/><br/>
      
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelkurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Spelmanslaget (se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>

        <tr>
          <td>18:00-19:30</td>
          <td>Danskurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>sista gången (se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Balkandansarna lär ut danser från Balkan. Kopanitsa spelar.</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Visstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Robert Qvist lär ut visor från Hälsingland.</small></td>
        </tr>
        <tr>
          <td>20:00-23:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Triol, Västkustfolk och Kristians kapell.</small></td>
        </tr>
      </table><br/>
      <small>Kafévärd denna afton: FMK:s styrelse + medarbetare</small>
    </center>
  </div>
</Concert>

  ,


<Concert
  title="Göran Håkansson &amp; Claes Hallgren"
  date="2024-11-01"
  imgMaxWidth="95%"
  cost={160} costYoung={130}
  img="img/program/ht2024/HakanssonHallgren-min.jpg"
>
  <div className="pBody">
    <center>
      Den här kvällen ligger fokus på värmländsk musik, med några utflykter till Røros, Jämtland/Härjedalen och Västerdalarna. Göran och Claes har spelat som duo sedan de deltog i Ransäterskurserna för 50 år sedan. De är båda riksspelmän med låtar från övre Fryksdalen och med läromästaren Olof Johansson, mer känd som ”Olle på Halla”, som förebild.
      <br/><br/>Med åren så har Västerdalsmusiken med Lejsme Pers musik samt Jämtland/Härjedalen och Røros blivit en viktig del i deras repertoar. Båda spelmännen är utpräglade dansspelmän och upplever samspelet mellan dansare och spelmän som något stort.<br/><br/>
      
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelkurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Spelmanslaget (se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>

        <tr>
          <td>18:00-19:30</td>
          <td>Tillsammansgruppen</td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Malte Hermansson och Kerstin Harvenberg lär ut finnskogspols. Spelman: Joar Skorpen.</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Spelstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Hans Grönlund lär ut låtar från östra Värmland.</small></td>
        </tr>
        <tr>
          <td>20:00-20:50</td>
          <td>Konsert: Göran Håkansson &amp; Claes Hallgren</td>
        </tr>
        <tr>
          <td>20:50-23:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Göran Håkansson &amp; Claes Hallgren</small></td>
        </tr>
        <tr>
          <td></td>
          <td><small>I pausen spelar Borås spelmanslag</small></td>
        </tr>
      </table><br/>
      <small>Kafévärd denna afton: FMK:s styrelse + medarbetare</small>
    </center>
  </div>
  </Concert>,


<Concert
  title="Studenter på Högskolan för scen och musik"
  date="2024-11-08"
  imgMaxWidth="95%"
  cost={0} // Frivillig entréavgift
  img="img/program/ht2024/HSM_2024_b-min.jpg"
  imgLink="https://www.gu.se/scen-musik/mer-om-kandidat-samtida-traditionsmusik"
>
  <div className="pBody">
    <center>
      På utbildningen i samtida traditionsmusik (tidigare världsmusikprogrammet) ägnar sig studenterna både åt fördjupning i olika former av traditionell musik, att hitta vägar att överbrygga gränserna mellan dessa traditioner och skapa nya musikaliska samarbeten.
      <br/><br/>En färgstark grupp individer från olika delar av världen möts och hittar tillsammans nya uttryck i sin musik, vilket kommer att vara uppenbart i denna konsert. Vare sig du vill lyssna till musik för att beröras på djupet eller för att ryckas med av danslust i kroppen så kommer denna upplevelse att vara för dig!
      <br/><br/>Detta är studenternas egna konsert, och vad som helst kan hända! Efter konserten bjuder studenterna in till traditionsenlig danskväll, där folklig dansmusik både från när och fjärran brukar dyka upp.<br/><br/>
      
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelkurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Spelmanslaget (se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Peter Nordqvist och Marie Schoonderwaldt lär ut schottisvariationer. Spelmän: Håkan och Ingela Lindberg.</small></td>
        </tr>
        <tr>
          <td>20:00-21:00</td>
          <td>Konsert: Studenter på HSM</td>
        </tr>
        <tr>
          <td>21:00-23:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Studenter på HSM</small></td>
        </tr>
      </table><br/>
      <small>Kafévärd denna afton: Bagaregårdens spelmanslag</small>
    </center>
  </div>
  </Concert>,

<Concert
  title="ENKEL"
  date="2024-11-15"
  imgMaxWidth="95%"
  cost={180} costYoung={150}
  img="img/program/ht2024/ENKEL_15_Nov_Photo_Jussi_Ojala-min.jpg"
  imgLink="https://www.enkelband.com/band.html"
>
  <div className="pBody">
    <center>
      ENKEL är ett traditionellt musikband från Finland som består av två durspel, kantele, viola och fyra röster; fyra personliga, unika och folkliga kvinnor med sina instrument, som delar en stor passion för traditionell musik och social folkdans och berättar historier genom instrumentala arrangemang.<br/><br/>
      Deras debutalbum Pappilan hääyö (Wedding Night at the Vicarage) släpptes 2016. Båda första albumen belönades som Årets finska folkmusikalbum och har fått positiv feedback internationellt. Sedan debuten 2014 har ENKEL uppträtt aktivt i Europa. I ENKEL ingår Leija Lautamaja (durspel, röst), Miia Palomäki (durspel, röst), Maija Pokela (kantele, röst) och Iida Savolainen (viola, röst).<br/><br/>
      
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelkurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Spelmanslaget (se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Jimmy Persson och Ingegerd Hägnesten lär ut humppa. Spelmän: Sune Löfström, Inger Blomstrand och Ingela Linderå.</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Visstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Ann-Britt Lindhult lär ut visor från Hälsingland.</small></td>
        </tr>
        <tr>
          <td>20:00-21:00</td>
          <td>Konsert: ENKEL</td>
        </tr>
        <tr>
          <td>21:00-23:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td><small>I pausen spelar Bagaregårdens spelmanslag</small></td>
        </tr>
      </table><br/>
      <small>Kafévärd denna afton: GNDS</small><br/>
      <small>Den här kvällen är ett samarbete med World sessions</small>
    </center>
  </div>
</Concert>
  ,


<Concert
  title="Spel- och danskväll"
  date="2024-11-22"
  imgMaxWidth="95%"
  cost={0} // Frivillig entréavgift
  img="img/arkiv/spel_och_dans.jpg"
>
  <div className="pBody">
    <center>
      Kristin &quot;Fornfela&quot; Svensson inleder dansspelet. Hon har även en visstuga i början av kvällen, och en viskurs på lördagen (se <a href="/kurser">kursinfo</a>).<br/><br/>
      
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelkurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Spelmanslaget (se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>
        <tr>
          <td>18:00-19:30</td>
          <td>Tillsammansgruppen</td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Visstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Kristin &quot;Fornfela&quot; Svensson lär ut visor efter Sven-Ingvar Heij och andra spelmän från Västergötland (se <a href="/kurser">kursinfo</a>).</small></td>
        </tr>
        <tr>
          <td>20:00-23:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Kristin &quot;Fornfela&quot; Svensson, Tageltrång och Lei-la inleder, sedan följer öppen spellista.</small></td>
        </tr>
      </table><br/>
      <small>Kafévärd denna afton: Polskedanslaget</small>
    </center>
  </div>
</Concert>

  ,


<Concert
title=<div>Folk Youth Minifestival #5<br/>Tonskalv och Flach | Østerby</div>
  date="2024-11-29"
  imgMaxWidth="95%"
  cost={180} costYoung={150}
  imgTwo="img/program/ht2024/Flach_Osterby_29_11-min.jpg"
  img="img/program/ht2024/TONSKALV_29_11_photo_Amanda_Lindgren-min.jpg"
>
  <div className="pBody">
    <center>
      Välkomna till Folk Youth Minifestival! Folkmusik och dans av och med unga personer.<br/><br/>
      Tonskalv är en kvartett som gärna tar ett kliv bort från det finstämda för att istället låta stråkarna knarra lite extra. Med sin unika sättning tolkar de den svenska folkmusiken på ett nyfiket och modernt sätt, sida vid sida med sitt egenkomponerade material. Blicken har de riktat mot sina kvinnliga företrädare vars historier de ger liv och röst. Genom fri improvisation, detaljrika arrangemang, historieberättande och tätt samspel tas lyssnaren med på en resa mellan förtvivlan och eufori. Död, hunger och favoritpolskor. Bas, fiol och flöjt.<br/><br/>
      Alice Evensen Landström (fiol, altfiol, sång), Alvina Herbinger Rygne (fiol), Daniella Eriksson Dahlström (elbas) och Daniela Söderlund (tvärflöjt, sång).<br/><br/>
      Flach | Østerby: Träd in i en värld av musikalisk symbios, där toner, harmonier och övertoner smälter samman i små instrumentala berättelser. Med accordion, nyckelharpa och hardingfele som uttryckssätt bjuder Anna och Henriette in dig till deras nordiska ljuduniversum av originalmusik. Att skriva ny musik med grund i det traditionella har de båda gjort i många år, och det har varit en drivkraft och en viktig del av deras musik - både tillsammans och var för sig. Flach | Østerby är aktuella hösten 2024 med sitt debutalbum som duo.<br/><br/>
      Anna Østerby (accordion) och Henriette Flach (nyckelharpa och hardingfele).<br/><br/>
      
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelkurs</td>
        </tr>
        <tr>
          <td>19:00-19:45</td>
          <td><small>Dansstuga för vana och ovana dansare med Folkdanssällskapet Nationals Ungdomslag</small></td>
        </tr>
        <tr>
          <td>20:00-20:50</td>
          <td>Konsert - Flach | Østerby</td>
        </tr>
        <tr>
          <td>20:50-21:30</td>
          <td>Dans (ovanvåningen)</td>
        </tr>
        <tr>
          <td>21:30-22:20</td>
          <td>Konsert - Tonskalv</td>
        </tr>
        <tr>
          <td>22:20-23:10</td>
          <td>Dans - Flach | Østerby</td>
        </tr>
        <tr>
          <td>23:10-00:00</td>
          <td>Dans - Tonskalv</td>
        </tr>
      </table><br/>
      <small>Kafévärd denna afton: Folk Youth och Folkdanssällskapets Nationals ungdomslag</small><br/>
      <small>Den här kvällen arrangeras i samarbete med Bilda och Folk Youth VG.</small>
      <br/>
      <br/>
      <img src="img/arkiv/FY_VG_color.png" style={{width: "30%"}} onClick={() => window.open("https://www.goteborgfolkfestival.com/folkyouth")} />
    </center>
  </div>
</Concert>
  ,


<Concert
  title="Glöggkompaniet"
  date="2024-12-06"
  imgMaxWidth="95%"
  cost={180} costYoung={150}
  img="img/program/ht2024/gloggkompaniet_foto_Josefina_Edensvard.jpg"
  imgLink="https://gloggkompaniet.se/"
>
  <div className="pBody">
    <center>
      Ta del av en stämningsfull julkonsert som sprider glädje och ljus! Glöggkompaniet är en hejdundrandes högtidsorkester som spelar traditionella och välkända julvisor i folklig ton. Med sången i fokus och med rötterna i nordisk och skotsk folkmusik gör Glöggkompaniet vemodiga, dansanta och medryckande tolkningar av vintermörkrets låtskatter.<br/><br/>
      Agnes Åhlund (sång &amp; cittra), Vendela Höök (sång &amp; fiol), Thor Ahlgren (sång, lutor, durspel, säckpipor och flöjter), Emma Engström (sång &amp; piano), Björn Petersson (sång &amp; kontrabas) och Magnus Heebøll (sång &amp; slagverk).<br/><br/>
      <i>För info: Det kommer vara ett par vikarierande musiker i bandet som ersätter Agnes Åhlund och Björn Petersson pga att de väntar barn precis i början av december. Ersättande musiker blir Petrix Hessel och Erik Bengtsson.</i>
      
      <table className="program-tabell">
        <tr>
          <td>17:30-19:15</td>
          <td>Spelkurs</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Spelmanslaget (se <a href="/kurser">kursinfo</a>)</small></td>
        </tr>
        <tr>
          <td>18:30-19:30</td>
          <td>Dansstuga</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Mats Nilsson och Ingegerd Sigfridsson lär ut vals. Spelman: Staffan Folestad.</small></td>
        </tr>
        <tr>
          <td>20:00-21:00</td>
          <td>Konsert: Glöggkompaniet</td>
        </tr>
        <tr>
          <td>21:00-23:00</td>
          <td>Spel till dans</td>
        </tr>
        <tr>
          <td></td>
          <td><small>Glöggkompaniet</small></td>
        </tr>
        <tr>
          <td></td>
          <td><small>I pausen spelar Mams &amp; Paps</small></td>
        </tr>
      </table><br/>
      <small>Kafévärd denna afton: Mams &amp; Paps</small>
    </center>
  </div>
</Concert>

];

const FadeInSection = ({
   children,
}) => {
   const domRef = React.useRef();

   const [isVisible, setVisible] = React.useState(false);

   React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
         // In your case there's only one element to observe:     
         if (entries[0].isIntersecting) {

            // Not possible to set it back to false like this:
            setVisible(true);

            // No need to keep observing:
            observer.unobserve(domRef.current);
         }
      });

      observer.observe(domRef.current);

      if (React.isValidElement(domRef.current)) {
         return () => observer.unobserve(domRef.current);
      }

   }, []);

   return (
      <section ref={domRef} className={isVisible ? 'entrySec is-visible' : 'entrySec'}>
         {children}
      </section>
   );
};


class Program extends Component {
   styles = {
      fontSize: 15,
      fontWeight: "bold"
   };

   constructor(props) {
      super(props);
      this.state = {
         isFetching: false,
         latest: null
      };
      this.sortArchive();
   }

   getProgram() {
      return theProgram;
   }

   sortArchive() {
      theProgram.sort((a, b) => {
         let dateDiff = a.props.date.localeCompare(b.props.date);
         if (dateDiff < 0) {
            return -1;
         } else if (dateDiff > 0) {
            return 1;
         }
         return 0;
      }
      );
   }

   render() {
      let theProgramCopy = [...theProgram];
      let scrollIndicator = (<ScrollIndicator />);

      while (theProgramCopy.length > 0) {
         let p = new Concert(theProgramCopy[0].props);
         if (!p.dateOK()) {
            theProgramCopy.shift();
         } else {
            break;
         }
      }

      if (theProgramCopy.length == 0) {
         return (
            <div>
               {openingSection}
               <center>
                  <h1>Tack för denna säsong!</h1>
                  Hoppas vi ses även nästa. Vi kommer snart att presentera kommande program.<br /><br />
                  <a href="/arkiv">Vårt arkiv över tidigare kvällar som varit</a>
                  <br /><br />
               </center>
            </div>
         );
      }

      if (theProgramCopy.length > 1) {
         let prog = [...theProgramCopy];
         let firstShow = prog.shift();
         /* All except the first one should have this swooch effect */
         let items = prog.map(concert => (
            <FadeInSection key={concert}>{concert}</FadeInSection>
         ));
         items.unshift(<div className="is-visible">{firstShow}</div>);
         return (<div>{scrollIndicator}{openingSection}{items}
            <br />
            <center>
               <a style={{
                  "font-size": "30px",
                  "border": "1px solid black",
                  "padding": "10px",
                  "border-radius": "5px"
               }} href="/arkiv">Tidigare program</a>
            </center>
            <br /><br />
            <br /></div>);
      }
     return (<div>{openingSection}{theProgram}
                   <center>
               <a style={{
                  "font-size": "30px",
                  "border": "1px solid black",
                  "padding": "10px",
                  "border-radius": "5px"
               }} href="/arkiv">Tidigare program</a>
            </center>
            <br /><br /></div>);
   }

}

export default Program;
